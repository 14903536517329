<template>
  <aside id="sidenav-main" class="sidenav navbar navbar-vertical navbar-expand-xs border-0 fixed-start bg-white overflow-x-hidden">
    <div class="sidenav-header-outer">
      <div class="sidenav-header">
        <NuxtLink class="m-0 d-flex flex-wrap justify-content-center" to="/">
          <img :src="runtimeConfig.public.logo" class="logo" alt="Logo Image">
        </NuxtLink>
      </div>

      <div class="sidenav-content d-flex justify-content-between gap-6 ">
        <div v-if="shop && client" class="d-flex justify-content-between ps-3 ms-1 gap-6">
          <form class="table w-100 mb-0 d-flex align-items-center">
            <div class="dt-search w-100 mb-0 pe-0">
              <input id="dt-search-0" type="search" class="dt-input w-100" placeholder="Nach Kundendaten suchen" aria-controls="DataTables_Table_0">
            </div>
          </form>
        </div>
        <div class="d-flex pe-3">
          <div v-if="shop && client && authStore.hasPermission(['Buchungen Bearbeiten'])" class="d-flex align-items-center gap-4">
            <Button @click="tseModal.open()" v-if="fiskalyClient" html-type="action" icon="ph:cash-register-light">
              {{ fiskalyClient.name }}
            </Button>
            <Button @click="router.push('/recent-orders')" :ignore-permission="true" html-type="secondary" icon="material-symbols-light:update">Meine Buchungen anzeigen</Button>
            <Button @click="createOrder(shop.id, client.id)" :ignore-permission="true" html-type="primary" icon="material-symbols-light:calendar-add-on">Neue Buchung anlegen</Button>
          </div>
          <div v-if="admin" class="border-gray border-start d-flex flex-wrap align-items-center justify-content-between ps-3 pe-3 ms-4">
            <div class="d-inline-flex gap-2 align-items-center">
              <NuxtImg v-if="admin.image" :src="admin.image" width="32" height="32" class="rounded-circle border-gray-custom" />
              <div class="lh-1 pe-2 overflow-hidden" style="max-width: 200px">
                <div>
                  <span class="text-lg text-primary fw-semibold">{{ admin.firstname }} {{ admin.lastname }}</span><br>
                </div>
                <Text class="text-sm opacity-10 text-truncate overflow-hidden w-100 lh-1">in <span class="fw-semibold text-primary ">{{ client.name }} {{ shop.name }}</span></Text>
              </div>
              <ButtonMore>
                <a @click="$emit('openCreateOrder')" v-if="shop && client && authStore.hasPermission(['Buchungen Bearbeiten'])" class="d-inline-flex align-items-center gap-1"><Icon class="text-lg" icon="material-symbols-light:moved-location" /> Neue Buchung in</a>

                <a @click="$emit('openCookies')" class="d-inline-flex align-items-center gap-1"><Icon class="text-lg" icon="material-symbols-light:compare-arrows" /> Wechseln</a>
                <a @click="tseModal.open()"><Icon class="text-lg" icon="ph:cash-register-light" /> Kassen</a>
                <a @click="signOut({ callbackUrl: '/login' });"><Icon class="text-lg" icon="material-symbols-light:power-settings-new" /> Logout</a>
              </ButtonMore>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-relative">
      <SidenavList />
    </div>
  </aside>
</template>

<script setup>
import SidenavList from "./SidenavList.vue";
import { getClient, getShop } from "~/composables/admin";
import { useAuthStore } from "~/stores/AuthStore";
import { useOrder } from "~/composables/useOrder";
import { useTSE } from "~/composables/useTSE";

defineEmits([ "openCookies", "openCreateOrder" ]);
const { runtimeConfig } = useNuxtApp();
const { data: admin, signOut } = useAuth();
const { createOrder } = useOrder();
const { tseModal, fiskalyClient } = useTSE();
const client = await getClient();
const shop = await getShop();
const authStore = useAuthStore();
const router = useRouter();
</script>
